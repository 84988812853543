<template>
  <div class="">
    <van-nav-bar left-arrow :title="$t('eas.详情')" fixed @click-left="$router.go(-1)" />
    <van-collapse v-model="activeNames" accordion style="margin-top: 55px">
      <div class="splitTitle">{{ $t('eas.基本信息') }}</div>
      <van-collapse-item :title="$t('eas.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('eas.银行授信申请号') }}</h5>
          <p>{{ ruleForm.applyNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.账套') }}</h5>
          <p>{{ ruleForm.applyOwnerName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.状态') }}</h5>
          <p>{{ ruleForm.status | setDict('PUB_WF_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.申请日期') }}</h5>
          <p>{{ ruleForm.applyDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.授信业务办理主体') }}</h5>
          <p>{{ ruleForm.creditBusinessEntityName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.授信品种') }}</h5>
          <p>{{ ruleForm.type | setDict('LC_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.计划开立日期') }}</h5>
          <p>{{ ruleForm.planOpenLcDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.授信业务办理银行') }}</h5>
          <p>{{ ruleForm.bankName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.原币币种') }}</h5>
          <p>{{ ruleForm.currency | setDict('CURRENCY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.预估合同金额') }}</h5>
          <p>{{ ruleForm.originalAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.对') + functionalCurrency + $t('eas.汇率') }}</h5>
          <p>{{ ruleForm.exchangeRmb | formatAmount(8,false) }}</p>
        </div>
        <div class="items">
          <h5>{{ functionalCurrency + $t('eas.授信金额') }}</h5>
          <p>{{ ruleForm.amountRmb | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.业务员') }}</h5>
          <p>{{ ruleForm.bizUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.业务部门') }}</h5>
          <p>{{ ruleForm.bizDeptName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.申请人') }}</h5>
          <p>{{ ruleForm.createUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.溢短装率') }}</h5>
          <p>{{ ruleForm.allowance ? ruleForm.allowance+'%':'' }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.合同金额') }}</h5>
          <p>{{ ruleForm.purContractAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.单据状态') }}</h5>
          <p>{{ ruleForm.orderState | setDict('EAS_CREDIT_ORDER_STATE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.生效状态') }}</h5>
          <p>{{ ruleForm.isAbolish | setDict('EAS_LC_EFFECITVE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.是否超授信') }}</h5>
          <p>{{ ruleForm.isOverCredit | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.信用证效期') }}</h5>
          <p>{{ ruleForm.lcValidPeriod }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.备注') }}</h5>
          <p>{{ ruleForm.remark }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('eas.合同信息')" name="2">
        <div v-if="!ruleForm.contractList || !ruleForm.contractList.length">{{ $t('eas.无') }}</div>
        <div v-for="(item, index) in ruleForm.contractList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('eas.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.采购合同号') }}</h5>
            <p>{{ item.purContractNo }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.供应商') }}</h5>
            <p>{{ item.supplierName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.付款类型') }}</h5>
            <p>{{ item.openLcMode | setDict('SALE_CONTRACT_PAYMENT_METHOD') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.开证原币金额') }}</h5>
            <p>{{ item.depositAmount | formatAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.合同信息-') + $t('eas.合同类型') }}</h5>
            <p>{{ item.contractType | setDict('PUR_CONTRACT_TYPE') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.合同信息-') + $t('eas.原币金额') }}</h5>
            <p>{{ item.contractAmount | formatAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.合同信息-') + $t('eas.对') + functionalCurrency + $t('eas.汇率') }}</h5>
            <p>{{ item.exchangeRateRmb | formatAmount(2,false) }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.合同信息-') + functionalCurrency + $t('eas.汇率') }}</h5>
            <p>{{ item.amountRmb }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.合同信息-') + $t('eas.归档状态') }}</h5>
            <p>{{ item.archiveStatus | setDict('ARCHIVING_STATUS') }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('eas.开立信息')" name="3">
        <div class="items">
          <h5>{{ $t('eas.授信单据号') }}</h5>
          <p>{{ this.ruleForm.lcNumber }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.开立日') }}</h5>
          <p>{{ this.ruleForm.openLcDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.到期日') }}</h5>
          <p>{{ this.ruleForm.expiredDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.归档编号') }}</h5>
          <p>{{ this.ruleForm.archiveNumber }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.额度转回日期') }}</h5>
          <p>{{ this.ruleForm.creditLineReturnDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.提前还款日期') }}</h5>
          <p>{{ this.ruleForm.prepaymentDate }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('eas.商品信息')" name="commodity">
        <materialsList
          :functionalCurrency="functionalCurrency"
          :contracts="ruleForm.contractList // 合同列表
              .map(({ purContType, purContractId }) => ({
                type: {'1': 'Cooperative', '2': 'DomesticTrade'}[purContType],
                contractId: purContractId
              }))
          "
        />
      </van-collapse-item>

      <div class="splitTitle">{{ $t('eas.附件') }}</div>
      <van-collapse-item :title="$t('eas.附件信息')" name="4">
        <div v-if="!attachmentList || !attachmentList.length">{{ $t('eas.无') }}</div>
        <div v-for="(item, index) in this.attachmentList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('eas.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.附件名称') }}</h5>
            <p>{{ item.attachmentName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.操作') }}</h5>
            <p>
              <van-icon name="eye-o" color="#19d2fe" size="20" @click="handleFileView(item.id,item.attachmentName)" />
<!--              <a href="#" @click="handleFileView(item.id,item.attachmentName)">{{ $t('eas.预览') }}</a>-->
<!--              <a href="#" @click="downloadAttachment(item.id,item.attachmentName)" style="margin-left: 7px">{{ $t('eas.下载') }}</a>-->
            </p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.上传人') }}</h5>
            <p>{{ item.createName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('eas.上传时间') }}</h5>
            <p>{{ item.createTime }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('eas.系统信息') }}</div>
      <van-collapse-item :title="$t('eas.系统信息')" name="5">
        <div class="items">
          <h5>{{ $t('eas.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.制单人') }}</h5>
          <p>{{ this.ruleForm.createUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('eas.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import util from '../../../libs/util';
import myHistoryPanel from '@/views/business/components/myHistoryPanel';
import myApproval from '@/views/business/components/myApproval';
import materialsList from '@/views/business/views/pur/materialsList';

export default {
  components: {
    myHistoryPanel,
    myApproval,
    materialsList
  },
  data() {
    return {
      attachmentList: [],
      loading: false,
      finished: false,
      activeNames: '1',
      hostName: '',
      task: '',
      personId: '',
      approvalShow: false,
      customSubmitBtnName: ['同意', '不同意'],
      id: '',
      key: '',
      title: '',
      detail: null,
      active: 0,
      businessKey: '',
      woCode: 'importLetterOfCredit',
      processInstanceName: '银行授信管理',
      mappingId: '',
      taskInstanceId: '',
      currentNode: '',
      variables: {},
      taskType: '',
      functionalCurrency: '',
      ruleForm: {
        id: '',
        applyNo: '',
        applyOwnerId: '',
        applyOwnerName: '',
        applyDate: '',
        creditBusinessEntityId: '',
        creditBusinessEntityName: '',
        type: '',
        bankId: '',
        bankName: '',
        planOpenLcDate: '',
        currency: '',
        originalAmount: 0,
        exchangeRmb: 0.0,
        exchangeUsd: 0.0,
        amountRmb: 0,
        amountUsd: 0,
        bizUserId: '',
        bizUserName: '',
        bizDeptId: '',
        bizDeptName: '',
        applyUserId: '',
        applyUserName: '',
        remark: '',
        openLcDate: '',
        lcNumber: '',
        expiredDate: '',
        creditLineReturnDate: '',
        prepaymentDate: '',
        importOrderNo: '',
        blNo: '',
        originalPayAmount: '',
        exchange: '',
        payAmountRmb: 0,
        isNegotiation: '',
        negotiationDays: 0,
        invoiceNo: '',
        negotiationRate: 0,
        negotiationInterest: 0,
        allowance: 0,
        payDate: '',
        status: 'PREPARING',
        orgId: '',
        deptId: '',
        isOpenLc: 'N',
        isAbolish: 'N',
        isChange: 'N',
        lastmodifiedUserName: '',
        lastOpt: 'EDIT',
        createUserName: '',
        importOrderId: '',
        purContractAmount: 0,
        orderState: 'PRE_OCCUPIED',
        billAcceptanceDate: '',
        importOrderDate: '',
        lcValidPeriod: '',
        archiveNumber: '',
        isOverCredit: 'N',
        bankAccountName: '',
        bankAccount: '',
        paymentNo: '',
        importOrderRemark: '',
        contractList: [],
        letterOfCreditImportOrder: []
      },
      historyList: [],
    };
  },
  props: {},
  methods: {
    /**
     * 根据文件id和文件名生成预览链接
     * @param id 附件id
     * @param fileName 附件名
     * @returns {string}
     */
    handleFileView (id, fileName) {
      this.$FilePrvwUtils.openFile(id,fileName);
    },
    downloadAttachment(id, fileName) {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/download",
        data: {
          "entity": {
            "id": id
          }
        },
        responseType: "arraybuffer"
      }).then(res => {
        // 成功回调
        var headers = res.headers;
        var blob = new Blob([res.data], {
          type: headers["content-type"]
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
    },
    getAttachmentList() {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/list",
        data: {
          "entity": {
            "bizId": this.ruleForm.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.attachmentList = backData;
        }
      });
    },
    findHistory() {
      const url = '/microarch/pur/purDomesticTradeHeader/list';
      let fixedQuery = [
        {
          field: 'originalContractNo',
          opt: '=',
          value: this.ruleForm.originalContractNo,
          assemble: 'and'
        },
        {
          field: 'isOldVersion',
          opt: '=',
          value: 'Y',
          assemble: 'and'
        }
      ];
      this.$myHttp({
        method: 'POST',
        url: url,
        data: {
          where: fixedQuery
        }
      }).then(res => {
        var data = res.data.rows;
        this.historyList = data;
      });
    },
    getProcessMappingId() {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.$myHttp({
        method: 'post',
        url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
        data: {
          entity: {
            woCode: this.woCode,
            orgId: userInfo.companyId,
            deptId: userInfo.deptId
          }
        }
      }).then(res => {
        if (res.data.ext.code === '2000') {
          this.mappingId = res.data.ext.mappingId;
        } else {
          Toast.fail({
            message: res.data.ext.message,
            type: 'error',
            duration: 1500
          });
        }
      });
    },
    backfilleditData(id) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/eas/letterOfCredit/view',
        data: {
          entity: {
            id: id
          }
        }
      }).then(res => {
        // 成功回调方法
        var data = res.data.entity;
        if (data) {
          this.ruleForm = res.data.entity;
          this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
          this.variables.activitiData = res.data.entity;
          this.getProcessMappingId();
          this.getAttachmentList();
          this.findHistory();
          this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        }
      });
    },
    setApprovalData(row) {
      if (row && row.pid) {
        this.ruleForm.id = row.businessKey;
        this.taskInstanceId = row.id;
        this.taskType = row.taskType;
      }
    },
    /**
     * 业务提交之前校验
     * */
    checkBeforeProcess(type) {
      if (type) {
        if (type === 'beforeAddsign') {
          this.$refs.demoTemperatureEdit.beforeAddsign();
        } else if (type === 'afterAddsign') {
          this.$refs.demoTemperatureEdit.afterAddsign();
        } else if (type === 'endProcess') {
          this.$refs.demoTemperatureEdit.endProcess();
        } else if (type === 'repelProcess') {
          this.repelProcess();
        } else {
          if (!this.taskInstanceId) {
            var params = {
              businessKey: this.ruleForm.id,
              mappingId: this.mappingId,
              processInstanceName: this.processInstanceName,
              userName: sessionStorage.getItem('userName'),
              variables: this.variables
            };
            this.startProcessAction(params);
          } else {
            this.$refs.demoTemperatureEdit.submit();
          }
        }
      } else {
        Toast.fail({
          message: this.$t('eas.数据校验失败'),
          type: 'warning'
        });
      }
    },
    // 业务发起流程
    startProcessAction(params) {
      if (params.mappingId) {
        params.mappingId = this.mappingId;
      }
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/eas/letterOfCredit/start',
        data: params
      })
        .then(res => {
          var backData = res.data.ext;
          if (backData) {
            this.$refs.demoTemperatureEdit.submitLoading = false;
            this.$refs.demoTemperatureEdit.isStart = true;
            this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
            this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
            this.$refs.demoTemperatureEdit.isFirstNode = true;
            this.$refs.demoTemperatureEdit.taskId = backData.taskId;
            this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
            this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
          } else {
            Toast.fail({
              message: this.$t('eas.流程发起异常'),
              type: 'warning'
            });
            this.$refs.demoTemperatureEdit.submitLoading = false;
          }
        })
        .catch(_ => {
          this.$refs.demoTemperatureEdit.submitLoading = false;
        });
    },
    // 业务提交流程
    submitProcessAction(params) {
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/eas/letterOfCredit/submit',
        data: params
      })
        .then(res => {
          if (res.data.ext.success) {
            this.processCallback(null, null, this.taskType);
          } else {
            Toast.fail({
              message: res.data.ext.msg,
              type: 'warning'
            });
          }
        })
        .catch(_ => {});
    },
    /***
     * 业务撤销流程
     */
    repelProcess() {
      var _this = this;
      _this
        .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
          cancelButtonClass: 'sysBackBtn'
        })
        .then(_ => {
          this.$myHttp({
            method: 'post',
            url: '/microarch/eas/letterOfCredit/undoProcess',
            data: {
              entity: {
                id: this.ruleForm.id,
                processInstanceId: this.ruleForm.processInstanceId
              }
            }
          })
            .then(res => {
              if (res.data.success === undefined || res.data.success) {
                Toast.fail({
                  message: this.$t('eas.撤销成功'),
                  type: 'success'
                });
                this.processCallback(null, null, this.taskType);
              } else {
                Toast.fail({
                  message: res.data.msg,
                  type: 'warning'
                });
              }
            })
            .catch(_ => {});
        })
        .catch(_ => {});
    },

    /***
     * 关闭发起流程审批窗口
     * 删除流程实例，回滚业务状态
     */
    closeStartCallback(pid) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/eas/letterOfCredit/deleteProcess',
        data: {
          entity: {
            id: this.ruleForm.id,
            processInstanceId: pid
          }
        }
      })
        .then(res => {
          this.$refs.demoTemperatureEdit.dialogVisible = false;
          this.$refs.demoTemperatureEdit.taskId = '';
          this.$refs.demoTemperatureEdit.processInstanceId = '';
          this.processCallback(null, null, this.taskType);
        })
        .catch(_ => {});
    },

    /**
     * 提交之前校验
     * */
    beforeSubmitCallBack(params) {
      // 业务参数校验 此时可以从params中获取提交审批所有的参数
      // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
      // 不通过给出相应的提示或者触发其他业务操作
      // let selectUserArray = params.candidateUserIds[params.nextId]
      // console.log(selectUserArray);
      this.submitProcessAction(params);
    },
    /**
     * 流程审批之后业务数据更改
     * */
    processCallback(piid, optionType, taskType) {
      var _this = this;
      // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
      // // 成功回调方法
      // if (taskType === 'transated') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/haveList'
      //   });
      // } else if (taskType === 'waitfor') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/toDoList'
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'sale/return/saleReturnApplyList'
      //   });
      //   this.$bus.emit('saleReturnApply-SaveSuccess');
      // }
      this.$router.go(-1);
    }
  },
  created() {},
  mounted() {
    this.functionalCurrency = util.getCurrencyName(this.ruleForm.orgId);
    let selectRow = JSON.parse(this.$route.query.row);
    if (selectRow) {
      this.ruleForm.id = selectRow.id;
      if (selectRow.businessKey) {
        this.ruleForm.id = selectRow.businessKey;
      }
    }
    if (this.ruleForm.id) {
      this.backfilleditData(this.ruleForm.id);
    }

    if (selectRow) {
      this.setApprovalData(selectRow);
    }
  },
  filters: {
    setDict(v, dictName) {
      return util.setDict(v, dictName);
    },
    formatAmount(v, decimal = 2, isCovering = true) {
      return util.formatAmount(v, decimal, isCovering);
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  overflow: hidden;

  .left-col {
    float: left;
    width: 60%;
  }

  .img {
    float: right;
  }
}

.visitor-details {
  p {
    color: #aab2bd;
  }

  h5 {
    font-weight: bold;
  }
}

.img {
  width: 40%;
  text-align: center;
  padding: 10px;
  position: relative;
  box-sizing: border-box;

  img {
    width: 100%;
  }
}

.panel-list {
  .panel {
    margin-top: 5px;
    background: #fff;
  }

  .divline {
    padding: 0 0 5px 0;
  }

  .icon-status {
    width: 50px;
    height: 45px;
    position: absolute;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 36px;
    right: 5px;
  }

  .type-0 {
    background-image: url('../../../images/icon-queryStatus-0.png');
  }

  .type-1 {
    background-image: url('../../../images/icon-queryStatus-1.png');
  }

  .type-2 {
    background-image: url('../../../images/icon-queryStatus-2.png');
  }

  .type-3 {
    background-image: url('../../../images/icon-queryStatus-3.png');
  }

  .type-4 {
    background-image: url('../../../images/icon-queryStatus-4.png');
  }
}

.table {
  margin-top: 5px;

  td {
    background: #f6f6f6;
    height: auto;
    padding: 8px 5px;
    border-bottom: 5px solid #fff;
  }
}

.personal-table {
  td {
    text-align: left;
    word-break: break-all;
  }

  img {
    vertical-align: middle;
  }

  .idcard {
    font-size: 12px;
    padding: 0;
  }
}

.car-table {
  td {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.visit-record {
  padding: 2px 0 0;

  ul {
    li {
      padding: 3px 0;
    }
  }
}
</style>
