<template>
  <!-- 境外 overseas-web/src/views/business/views/pur/purCooperative/purCooperativeDetailEdit.vue -->
  <!-- 境内 overseas-web/src/views/business/views/pur/purDomesticTrade/purDomesticTradeDetailEdit.vue -->
  <div v-if="!commodities || !commodities.length">{{ $t('pur.无') }}</div>
  <div v-else>
    <div v-for="(item, index) in this.commodities" :key="index" class="detailLine">
      <div class="items">
        <h5>{{ $t('pur.序号') }}</h5>
        <p>{{ index + 1 }}</p>
      </div>
      <div class="items">
        <h5>{{ $t('pur.商品名称') }}</h5>
        <p>{{ item.commodityDetails }}</p>
      </div>
      <div class="items">
        <h5>{{ $t('pur.商品类别') }}</h5>
        <p>{{ item.commodityCategoryName }}</p>
      </div>
      <div class="items">
        <h5>{{ $t('pur.产品工厂型号') }}</h5>
        <p>{{ item.specification }}</p>
      </div>
      <div class="items">
        <h5>{{ $t('pur.商品英文描述') }}</h5>
        <p>{{ item.englishDescription }}</p>
      </div>
      <div class="items">
        <h5>{{ $t('pur.数量') }}</h5>
        <p>{{ item.contactNumber }}</p>
      </div>
      <div v-if="'DomesticTrade' === item.type" class="items">
        <h5>{{ $t('pur.含税单价') }}</h5>
        <p>{{ item.taxUnitPrice }}</p>
      </div>
      <div v-if="'DomesticTrade' === item.type" class="items">
        <h5>{{ $t('pur.含税金额') }}</h5>
        <p>{{ item.taxInclusiveAmount }}</p>
      </div>
      <div v-if="'DomesticTrade' === item.type" class="items">
        <h5>{{ $t('pur.不含税金额') }}</h5>
        <p>{{ item.taxExcludingAmount }}</p>
      </div>
      <div v-if="'Cooperative' === item.type" class="items">
        <h5>{{ $t('pur.原币单价') }}</h5>
        <p>{{ item.contactPrice }}</p>
      </div>
      <div v-if="'Cooperative' === item.type" class="items">
        <h5>{{ $t('pur.原币金额') }}</h5>
        <p>{{ item.contactCurrency }}</p>
      </div>
      <div v-if="'Cooperative' === item.type" class="items">
        <h5>{{ functionalCurrency }}{{ $t('pur.金额') }}</h5>
        <p>{{ item.contactRmb }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // [{ type: '合同类型:境内DomesticTrade/境外Cooperative', contractId: '合同ID' }]
    contracts: { type: Array, default: () => [] },
    functionalCurrency: { type: String }
  },
  data() {
    return {
      commodities: []
    };
  },
  created() {
    // 查询采购合同列表, 获取其商品列表
    Promise.all(this.contracts.map(contract => this.buildHttp(contract))).then(responses => {
      this.commodities = responses.flatMap(item => item);
    });
  },
  methods: {
    buildHttp({ type, contractId: id }) {
      switch (type) {
        case 'DomesticTrade': // 境内采购
          return this.$myHttp.post('/microarch/pur/purDomesticTradeHeader/view', { entity: { id } }).then(response => {
            return response.data.entity.purDomesticTradeLineList.map(item => ({ ...item, type }));
          });
        case 'Cooperative': // 境外采购
          return this.$myHttp.post('/microarch/pur/purCooperativeHeader/view', { entity: { id } }).then(response => {
            return response.data.entity.purCooperativeLineList.map(item => ({ ...item, type }));
          });
        default:
          return Promise.resolve([]);
      }
    }
  }
};
</script>
